<template>
    <div ref="element" class="element-experience border shadow" v-resize @resize="layoutResize" @click="selected=!selected">
        <!-- left: picture -->
        <div class="left img-container"
             :class="selected ? 'element-select' : null"
        >
            <b-carousel
                id="experience-route-carousel"
                v-model="slide"
                :interval="0"

                background="#ababab"
                fade
                :controls="!getPictures.defaultPicture"
                :indicators="!getPictures.defaultPicture"
            >
                <b-carousel-slide v-for="picture in getPictures.pictures">
                    <template #img>
                        <b-img
                            fluid-grow
                            :src="picture"
                            :style="{'max-height': height}"
                        />
                    </template>
                </b-carousel-slide>
            </b-carousel>
        </div>
        <!-- right -->
        <div class="right" :class="selected ? 'element-select' : null">
            <!-- right-top -->
            <div class="right-top px-1 pt-1 text-secondary" v-html="getTitle">
            </div>
            <!-- right-bottom -->
            <div class="right-bottom mx-1 border-top border-secondary">
                <div class="d-flex justify-content-between align-items-center" style="height: 30px">
                    <!-- duration -->
                    <div class="text-secondary font-weight-bolder">
                        <b-icon-clock/>
                        {{getContent.duration}}
                    </div>
                    <!-- buttons -->
                    <div class="d-inline-block">
                        <!-- button: bestAdultPrice -->
                        <b-icon-cash
                            v-if="!__isNull(getBestAdultPrice)"
                            class="ml-1"
                            variant="secondary"
                            scale="1.2"
                            v-b-tooltip.topleft.hover.v-secondary.html="$t('adventure.sider_experience.best_adult_price')  + ' <b>' + getBestAdultPrice + '</b>'"
                        />
                        <!-- button: show details -->
                        <b-icon-geo-alt-fill
                            class="cursor ml-1"
                            variant="secondary"
                            scale="1.2"
                            @click.stop="showDetails()"
                            v-b-tooltip.topleft.hover.v-info.html="$t('adventure.experience.tooltip_experience_detail')"
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

// @group ADVENTURE2
export default {
    name: "ElementRouteExperience",
    components: {},
    props: {
        data: {
            type: Object,
            default() {
                return {}
            }
        },
        /*
* Hotlink for marking selected markers
* <p>
*   {"destinationId": "number", "experienceId": "number", "selected": "boolean", "popupOpen": "boolean"}
*</p>
*/
        hotlink: {
            type: Object,
            default() {
                return {
                    destinationId: null,
                    experienceId: null,
                    selected: false,
                }
            }
        },
    },
    data() {
        return {
            height: null,
            slide: 0,
        }
    },
    mounted() {
    },
    methods: {
        // <-- layout -->
        layoutResize(e) {
            // console.log("eee", this.$refs.element.clientHeight, e.detail.height, e.srcElement.clientHeight, e);
            this.height = e.srcElement.clientHeight - 2 + 'px';
        },
        // <--  -->
        showDetails() {
            this.$emit('eventShowDetails', {
                destinationId: this.data.destinationId,
                experienceId: this.data.experienceId,
            });
        },
    },
    computed: {
        selected: {
            get(){
                if (this.__isNull(this.data)) {
                    return false;
                }
                if ((this.hotlink.experienceId === null && this.data.destinationId === this.hotlink.destinationId)
                    || this.data.experienceId === this.hotlink.experienceId) {
                    return this.hotlink.selected;
                }
                return false;
            },
            set(value) {
                /*
                * Trigger on selected change.
                * <p>
                * return:<br>
                *   {"destinationId": "number", "experienceId": "number", "selected": "boolean"}
                *</p>
                 */
                this.$emit('eventSelected', {
                    destinationId: this.data.destinationId,
                    experienceId: this.data.experienceId,
                    selected: this.data.experienceId !== this.hotlink.experienceId ? true : value
                });
            }
        },
        getTitle() {
            let value = `<b>${this.data.content.title}</b>`;
            value += this.__isNull(this.data.content.subtitle) ? '' : '<br>' + this.data.content.subtitle;
            // <--  -->
            return value;
        },
        getPictures() {
            let picturesSet = new Set();
            let pictures = this.data.pictures;
            let defaultPicture = false;
            // <-- experience pictures -->
            pictures.experience.forEach(picture => {
                picturesSet.add(this.__getImageSrc(picture.image.src_p));
            });
            // <-- destination pictures -->
            pictures.destination.forEach(picture => {
                picturesSet.add(this.__getImageSrc(picture.image.src_p));
            });
            // <-- no image -->
            if (picturesSet.size === 0) {
                defaultPicture = true;
                picturesSet.add(require('@/assets/images/default.jpg'));
            }
            // <--  -->
            return {
                pictures: Array.from(picturesSet),
                defaultPicture: defaultPicture
            };
        },
        getContent() {
            if (this.__isNull(this.data) || this.__isNull(this.data.content)) {
                return {
                    title: this.$t('form.no_data'),
                    hide: true,
                    subTitle: null,
                    duration: null,
                }
            }
            // <--  -->
            let content = this.data.content;
            // <-- duration -->
            let duration = this.__isNull(content.duration.days) ? '' : content.duration.days + ' d';
            let time = content.duration.time;
            if (!this.__isNull(content.duration.time)) {
                duration += duration.length === 0 ? '' : ', ';
                time = this.__trimmedShapeTime(time);
                duration += this.__isNull(content.duration.time) ? '' : time + ' h';
            }
            // <--  -->
            return {
                title: content.title,
                subTitle: content.subTitle,
                duration: duration,
            }
        },
        getBestAdultPrice() {
            if (this.__isNull(this.data.bestAdultPrice)) {
                return null;
            }
            // <--  -->
            let bap = this.data.bestAdultPrice;
            // <--  -->
            return `${this.__getDecimalLocale(bap.amount)} ${bap.currency}`;
        }
    },
    filters: {},
    watch: {}
}
</script>

<style scoped>
.element-experience {
    width: 240px;
    height: 140px;
    border-radius: 5px;
    display: flex;
    flex-direction: row;
}

.left {
    /*background: #42b983;*/
    width: 40%;
    /*height: calc(inherit - 2px); not working*/
    margin: 1px;
    display: flex;
    vertical-align: center;
    justify-content: center;
}

.right {
    /*background: #e83e8c;*/
    width: 60%;
    margin-left: 1px;
    margin-right: 1px;
    /*height: calc(inherit - 2); not working*/
    display: flex;
    flex-direction: column;
    font-size: 10pt;
}

.right-top {
    /*background: #17a2b8;*/
    flex: 1 1 auto;
    overflow: auto;
}

.right-bottom {
    /*background: #fd7e14;*/
    height: 30px;
}

.cursor {
    cursor: pointer;
}


.element-select {
    background: #FDFF2F;
    /*background: #F800E6;*/
    /*background: #2AFB08;*/
}

</style>
