<template>
    <div class="overflow-auto" v-resize @resize="layoutTemplateResize"
         :style="{height: getTemplateHeight.px}"
    >
        <!-- title -->
        <div class="pl-2 d-flex align-items-center" v-resize @resize="layoutHeaderResize" style="height: 30px">
            <div >{{$t('adventure.sider_experience.adventures')}}</div>
            <div style="width: 100%" class="ml-2 d-flex justify-content-end">{{selectedRouteName}}</div>
        </div>
        <!-- body -->
        <splitpanes class="default-theme" :style="{height:getBodyHeight.px}">
            <!-- pane: experiences -->
            <pane :min-size="getPaneSize.min" :max-size="getPaneSize.max" :size="getPaneSize.size">
                <!-- adventures -->
                <panel-adventure
                    :title="$t('menu.adventure.adventures') + ` (${routes.length})`"
                    :label1-text="null"
                    :fixed-width="false"
                    :width="paneMinWidth.px"
                    :max-height="getBodyHeight.value"
                    @refresh="loadRoutesFromBase()"
                    :draggable-off="true"
                >
                    <!-- body -->
                    <template #draggable-body-1>
                        <div class="d-flex flex-column">
                            <div v-for="(route, index) in routes" class="mb-1">
                                <folding-card
                                    :title="route.name"
                                    :header-bg-variant="index % 2 === 0 ? 'secondary' : 'primary'"
                                    @foldingChange="loadAdventureFromBase($event, route.id, route.name)"
                                >
                                    <!-- buttons -->
                                    <template #headerButtons>
                                        <!-- icon when route is private -->
                                        <div v-if="route.private"
                                             v-b-tooltip.topright.hover.v-info.html="$t('adventure.route.private')"
                                        >
                                            <b-icon-key-fill
                                                variant="info"
                                                class="ml-1 py-0"
                                                scale="1.2"/>
                                        </div>
                                        <!-- button: show route -->
                                        <b-button
                                            class="ml-1 py-0"
                                            size="sm"
                                            @click.stop="showRoute(route.id, route.name)"
                                        >
                                            <b-icon-pin-map scale="0.7"/>
                                        </b-button>
                                        <!-- button: booking route -->
                                        <div v-if="isBookingDisabled" class="d-inline-block"
                                             v-b-tooltip.bottomright.hover.v-danger.html="$t('adventure.route.noLogin_message.book')"
                                        >
                                            <b-button class="ml-1 py-0" size="sm" disabled>
                                                <b-icon-book scale="0.8"/>
                                            </b-button>
                                        </div>
                                        <b-button
                                            v-else
                                            class="ml-1 py-0"
                                            size="sm"
                                            v-b-tooltip.topright.hover.v-info.html="$t('adventure.route.booking_route_title_html', {route_name: route.name})"
                                            @click.stop="openModalBooking(route)"
                                        >
                                            <b-icon-book scale="0.8"/>
                                        </b-button>
                                    </template>
                                    <!-- body -->
                                    <template #body v-if="!__isNull(adventures[route.id])">
                                        <!-- info -->
                                        <folding-card
                                            :title="$t('form.info')"
                                            class="mt-1"
                                            header-bg-variant="white"
                                            :header-text-variant="index % 2 === 0 ? 'secondary' : 'primary'"
                                            :is-inside="true"
                                        >
                                            <template #body>
                                                <!-- id, stamp -->
                                                <div
                                                    class="mx-1 d-flex d-flex flex-row flex-wrap justify-content-between">
                                                    <div class="flex-grow-1 flex-shrink-1 pr-1">id:
                                                        {{adventures[route.id].id}}
                                                    </div>
                                                    <div>{{adventures[route.id].stamp}}</div>
                                                </div>
                                                <!-- subtitle -->
                                                <div class="mx-1 font-weight-bold">{{adventures[route.id].subtitle}}
                                                </div>
                                                <!-- description -->
                                                <div class="mx-1 my-1" v-html="adventures[route.id].description"></div>
                                            </template>
                                        </folding-card>
                                        <!-- contractor -->
                                        <folding-card
                                            v-if="!__isNull(adventures[route.id].contractor)"
                                            :title="$t('adventure.contractor')"
                                            class="mt-1"
                                            header-bg-variant="white"
                                            :header-text-variant="index % 2 === 0 ? 'secondary' : 'primary'"
                                            :is-inside="true"
                                        >
                                            <template #body>
                                                <div class="mx-1 my-1">
                                                    <!-- name -->
                                                    <div class="font-weight-bold">
                                                        {{adventures[route.id].contractor.name}}
                                                    </div>
                                                    <!-- address -->
                                                    <div class="mt-1">{{adventures[route.id].contractor.street}}</div>
                                                    <div>{{adventures[route.id].contractor.postOffice}}</div>
                                                    <div>{{adventures[route.id].contractor.region}}</div>
                                                    <div>{{adventures[route.id].contractor.state}}</div>
                                                    <div>{{adventures[route.id].contractor.continent}}</div>
                                                </div>
                                            </template>
                                        </folding-card>
                                        <!-- content -->
                                        <folding-card
                                            v-if="!__isNull(adventures[route.id].content)"
                                            :title="$t('form.content')"
                                            class="mt-1"
                                            header-bg-variant="white"
                                            :header-text-variant="index % 2 === 0 ? 'secondary' : 'primary'"
                                            :is-inside="true"
                                        >
                                            <template #body>
                                                <div class="px-1 pt-2" v-html="adventures[route.id].content"></div>
                                            </template>
                                        </folding-card>
                                        <!-- schoolExcursion -->
                                        <folding-card
                                            v-if="!__isNull(adventures[route.id].schoolExcursion)"
                                            :title="$t('adventure.school_excursion')"
                                            class="mt-1"
                                            header-bg-variant="white"
                                            :header-text-variant="index % 2 === 0 ? 'secondary' : 'primary'"
                                            :is-inside="true"
                                        >
                                            <template #body>
                                                <div class="px-1 pt-2"
                                                     v-html="adventures[route.id].schoolExcursion"></div>
                                            </template>
                                        </folding-card>
                                        <!-- travelDates -->
                                        <folding-card
                                            v-if="!__isNull(adventures[route.id].travelDates)"
                                            :title="$tc('adventure.travel_date', 0)"
                                            class="mt-1"
                                            header-bg-variant="white"
                                            :header-text-variant="index % 2 === 0 ? 'secondary' : 'primary'"
                                            :is-inside="true"
                                        >
                                            <template #body>
                                                <div class="px-1 pt-1" v-for="item in adventures[route.id].travelDates">
                                                    <div>
                                                        <b>{{__convertBaseDateToLocaleDateString(item.departureDate)}}</b>
                                                        -
                                                        {{__convertBaseDateToLocaleDateString(item.arrivalDate)}}
                                                        ({{$tc('adventure.accommodation', 1)}}: {{item.durationDays}})
                                                    </div>
                                                    <div class="editor-text" v-html="item.locale.notes"/>
                                                </div>
                                            </template>
                                        </folding-card>
                                        <!-- transporter -->
                                        <folding-card
                                            v-if="!__isNull(adventures[route.id].transporter)"
                                            :title="$tc('adventure.transporter', 0)"
                                            class="mt-1"
                                            header-bg-variant="white"
                                            :header-text-variant="index % 2 === 0 ? 'secondary' : 'primary'"
                                            :is-inside="true"
                                        >
                                            <template #body>
                                                <div v-for="item in adventures[route.id].transporter" class="px-1 pt-1">
                                                    <!-- name, route_text -->
                                                    <div v-if="!__isNull(item.transporter)" style="min-width: 50px">
                                                        <b>{{$tc('adventure.transporter', 1)}}</b>: {{item.transporter}}
                                                    </div>
                                                    <div v-if="!__isNull(item.route)" style="min-width: 50px">
                                                        <b>{{$tc('adventure.route_text', 1)}}</b>:
                                                        {{item.route}}
                                                    </div>
                                                    <!-- notes -->
                                                    <div class="mt-1 editor-text" v-if="item.locale !== undefined"
                                                         v-html="item.locale.notes"></div>
                                                </div>
                                            </template>

                                        </folding-card>
                                        <!-- insurance -->
                                        <folding-card
                                            v-if="!__isNull(adventures[route.id].insurance)"
                                            :title="$tc('adventure.insurance', 0)"
                                            class="mt-1"
                                            header-bg-variant="white"
                                            :header-text-variant="index % 2 === 0 ? 'secondary' : 'primary'"
                                            :is-inside="true"
                                        >
                                            <template #body>
                                                <div class="m-1">
                                                    <div v-for="item in adventures[route.id].insurance">
                                                        <!-- name, price -->
                                                        <div v-if="!__isNull(item.name)" style="min-width: 50px">
                                                            <b>{{$tc('form.name', 1)}}</b>: {{item.name}}
                                                        </div>
                                                        <div v-if="!__isNull(item.price)" style="min-width: 50px">
                                                            <b>{{$tc('adventure.price', 0)}}</b>:
                                                            {{item.price}}
                                                        </div>
                                                        <!-- notes -->
                                                        <div class="mt-1 editor-text" v-if="!__isNull(item.description)"
                                                             v-html="item.description"></div>
                                                    </div>
                                                </div>
                                            </template>
                                        </folding-card>
                                        <!-- pictures -->
                                        <folding-card
                                            v-if="!__isNull(adventures[route.id].pictures)"
                                            :title="$t('form.tab.picture')"
                                            class="mt-1"
                                            header-bg-variant="white"
                                            :header-text-variant="index % 2 === 0 ? 'secondary' : 'primary'"
                                            :is-inside="true"
                                        >
                                            <template #body>
                                                <div class="p-1 d-flex flex-row flex-wrap">
                                                    <b-img class="destination-img" no-body
                                                           v-for="(picture, index) in adventures[route.id].pictures"
                                                           :src="__getImageSrc(picture.image.src_p)"
                                                           style="cursor: pointer"
                                                           @click="openModalPicture(index)"
                                                    />
                                                </div>
                                            </template>

                                        </folding-card>
                                        <!-- experiences -->
                                        <folding-card
                                            v-if="!__isNull(adventures[route.id].experiences)"
                                            :title="$t('adventure.sider_menu.experience')"
                                            class="mt-1"
                                            header-bg-variant="white"
                                            :header-text-variant="index % 2 === 0 ? 'secondary' : 'primary'"
                                            :is-inside="true"
                                        >
                                            <template #body>
                                                <div class="my-2 mx-1 d-inline-block"
                                                     v-for="(experience, index) in adventures[route.id].experiences"
                                                >
                                                    <element-route-experience
                                                        :data="experience"
                                                        :hotlink="hotlink"
                                                        @eventSelected="eventSelected"
                                                        @eventShowDetails="eventShowExperienceDetails"
                                                    />
                                                </div>
                                            </template>
                                        </folding-card>
                                        <!-- miscellaneous -->
                                        <folding-card
                                            v-if="!__isNull(adventures[route.id].miscellaneous)"
                                            :title="$t('form.miscellaneous')"
                                            class="mt-1"
                                            header-bg-variant="white"
                                            :header-text-variant="index % 2 === 0 ? 'secondary' : 'primary'"
                                            :is-inside="true"
                                        >
                                            <template #body>
                                                <!-- content -->
                                                <div class="px-1 pt-2 pb-1"
                                                     v-html="adventures[route.id].miscellaneous"></div>
                                            </template>
                                        </folding-card>
                                        <!-- gtac -->
                                        <folding-card
                                            v-if="!__isNull(adventures[route.id].gtac)"
                                            :title="$tc('business.gtac', 1)"
                                            class="mt-1"
                                            header-bg-variant="white"
                                            :header-text-variant="index % 2 === 0 ? 'secondary' : 'primary'"
                                            :is-inside="true"
                                        >
                                            <template #body>
                                                <!-- content -->
                                                <div class="px-1 pt-2 pb-1"
                                                     v-html="adventures[route.id].gtac"></div>
                                            </template>
                                        </folding-card>
                                        <!--  -->
                                    </template>
                                    <!-- footer -->
                                    <template #footer>
                                        <div v-if="!__isNull(adventures[route.id])"
                                             class="p-2 flex-grow-1 d-flex justify-content-center bg-white border-top">
                                            <b-form-group class="text-center m-0 ">
                                                <!-- booking -->
                                                <b-button :disabled="isBookingDisabled"
                                                          :variant="index % 2 === 0 ? 'secondary' : 'primary'"
                                                          @click="openModalBooking(route)"
                                                >
                                                    <b-icon-book scale="0.8"/>
                                                    {{$tc('adventure.sider_experience.booking', 1)}}
                                                </b-button>
                                                <b-form-invalid-feedback :state="!isBookingDisabled" class="p-0 m-0">
                                                    <div v-html="$t('adventure.route.noLogin_message.book')"/>
                                                </b-form-invalid-feedback>
                                            </b-form-group>
                                        </div>
                                    </template>
                                </folding-card>
                            </div>
                        </div>
                    </template>
                </panel-adventure>
            </pane>
            <!-- pane: leaflet map -->
            <pane>
                <!-- <leaflet-map-->
                <panel-map style="width:100%">
                    <template #map>
                        <!-- @popupOpen="popupOpen"-->
                        <leaflet-map2
                            :routing="routing"
                            :hotlink="hotlink"
                            @eventClearHotlink="clearHotlink()"
                        >
                            <!-- map body -->
                            <template #popupBody>
                                <popup-destination2
                                    :data="popupData"
                                    :hotlink.sync="hotlink"
                                />
                            </template>
                        </leaflet-map2>
                    </template>
                </panel-map>
            </pane>
        </splitpanes>
        <!-- modal images -->
        <b-modal
            :id="`modal-sider-route-pictures`"
            hide-footer
            size="lg"
        >
            <!-- carousel -->
            <b-carousel
                v-if="getPictures !== null"
                id="sider-route-pictures-carousel"
                v-model="slide"
                :interval="0"
                background="#ababab"
                class="img-container mt-1"
                controls
                fade
                indicators
                @sliding-start="onSlideStart"
                @sliding-end="onSlideEnd"
            >
                <b-carousel-slide
                    v-for="picture in getPictures"
                    style="text-shadow: 0px 0px 2px #000; text-align: center;"
                    fade
                    background="white"
                >
                    <template #img>
                        <b-img
                            fluid
                            :src="picture.image.src"
                            class="rounded-0"
                            :style="{width: picture.modalImageSize.width, height: picture.modalImageSize.height}"
                        />
                    </template>
                </b-carousel-slide>
            </b-carousel>
        </b-modal>
        <!-- modal booking -->
        <modal-route-booking2
            :route="selectedRoute"
        />
    </div>
</template>

<script>
import PanelAdventure from "@/components/_public/_adventures2/elements/PanelAdventure";
import PanelMap from "@/components/_public/_adventures2/elements/PanelMap";
import FoldingCard from "@/components/general/elements/layoutsElements/FoldingCard";
import ElementRouteExperience from "@/components/_public/_adventures2/elements/ElementRouteExperience";
import ModalRouteBooking2 from "@/components/_public/_adventures2/adventures/ModalRouteBooking2";
import LeafletMap2 from "@/components/_public/_adventures2/elements/LeafletMap2";
import PopupDestination2 from "@/components/_public/_adventures2/elements/PopupDestination2";

// @group ADVENTURE2
export default {
    name: "Adventures",
    components: {
        PopupDestination2,
        LeafletMap2,
        ModalRouteBooking2,
        ElementRouteExperience,
        FoldingCard,
        PanelMap,
        PanelAdventure
    },
    props: {},
    data() {
        return {
            /**
             * @vuese
             * Use for calculating layout heights and/or widths.
             */
            templateLayout: {},
            /**
             * @vuese
             * Use for calculating layout heights and/or widths.
             */
            layout: [],
            // <-- pane experience -->
            paneMinWidth: {
                value: 200,
                px: 200 + 'px',
            },
            // <-- pane map -->
            paneMapMinWidth: {
                value: 480,  // <-- should bi modal window width -->
                px: 480 + 'px'
            },
            // <-- base -->
            /**
             * @vuese
             * URLs for calling api
             */
            uri: {
                main: `api/v1/_public`,
                getDestination: `api/v1/_public/get_destination`,
            },
            routes: [],
            adventures: {},
            listExperiences: [],
            routing: [],
            popupData: {},
            selectedRouteId: 0,
            selectedRouteName: null,
            // <-- pictures -->
            slide: 0,
            imageWidthMax: 765,
            imageHeightMax: 600,
            // <-- leafletMap -->
            hotlink: {
                source: null,
                destinationId: null,
                experienceId: null,
                selected: false,
                popupOpen: false,
            },
            // <-- booking -->
            selectedRoute: {},
        }
    },
    mounted() {
        this.loadRoutesFromBase();
    },
    methods: {
        // @ignore <-- layout -->
        layoutTemplateResize(e) {
            this.templateLayout = {
                width: e.detail.width,
                height: e.detail.height,
            };
        },
        /**
         * @vuese
         * Added layout data (width, height) for calculating page or component optimal size (height).
         * @arg event
         */
        layoutHeaderResize(e) {
            let layout = this.__layoutAdd(this.layout, 'title', e.detail.width, e.detail.height);
        },
        // <-- data -->
        clearHotlink() {
            this.hotlink = {
                source: null,
                destinationId: null,
                experienceId: null,
                selected: false,
                popupOpen: false,
            }
        },
        // <-- pictures -->
        onSlideStart(slide) {
            this.sliding = true;
        },
        onSlideEnd(slide) {
            this.sliding = false;
        },
        openModalPicture(index) {
            this.slide = index;
            this.$bvModal.show(`modal-sider-route-pictures`);
        },
        // <-- actions -->
        showRoute(routeId, routeName) {
            this.$root.mx_busy = true;
            // <-- api -->
            const config = {
                headers: this.__getHeaders()
            };
            // <--  -->
            this.axios.get(this.uri.main + `/getRouteForRouting/${routeId}`, config)
                .then((response) => {
                    this.$set(this, 'routing', response.data);
                    this.selectedRouteName = routeName;
                    // <--  -->
                    this.$root.mx_busy = false;
                })
                .catch((error) => {
                    // console.log("ERROR:", error.response.data);
                    // this.$root.mx_busy = false;
                });
        },
        openModalBooking(route) {
            this.selectedRoute = route;
            this.$bvModal.show('ModalRouteBooking2');
        },
        // <-- events -->
        /*
        * When selected experienceId == null -> select all elements where destinationId matches.
        * If experienceId > 0 then select only the element where experienceId matches.
        */
        eventSelected(data) {
            data.source = this.$options.name;
            data.popupOpen = false;
            this.hotlink = data;
        },
        eventShowExperienceDetails(data) {
            let hotlink = this.hotlink;
            // <-- same experience -->
            if (data.experienceId === hotlink.experienceId) {
                hotlink.popupOpen = !hotlink.popupOpen;
            }
            // <-- other experience -->
            else {
                hotlink = data;
                hotlink.selected = true;
                hotlink.popupOpen = true;
            }
            // <--  -->
            hotlink.source = null;
            this.hotlink = hotlink;
        },
        // <-- base -->
        loadRoutesFromBase() {
            this.$root.mx_busy = true;
            this.adventures = {};
            // <-- api -->
            const config = {
                headers: this.__getHeaders()
            };
            // <--  -->
            // this.axios.get(this.uri.main, config)
            this.axios.get(this.uri.main + '/get_adventure_routes', config)
                .then((response) => {
                    this.$set(this, 'routes', response.data);
                    this.$root.mx_busy = false;
                })
                .catch((error) => {
                    // console.log("ERROR:", error.response.data);
                    // this.$root.mx_busy = false;
                });
            this.routes = [];
        },
        loadAdventureFromBase(e, routeId, routeName) {
            if (e.folded) {
                return;
            }
            // <--  -->
            this.$root.mx_busy = true;
            this.clearHotlink();
            let adventures = this.__clone(this.adventures);
            adventures[routeId] = null;
            // <-- api -->
            const config = {
                headers: this.__getHeaders()
            };
            // <--  -->
            this.axios.get(this.uri.main + `/get_adventure_route/${routeId}`, config)
                .then((response) => {
                    // <--  -->
                    adventures[routeId] = response.data;
                    this.selectedRouteId = routeId;
                    this.$set(this, 'adventures', adventures);
                    // <--  -->
                    this.$root.mx_busy = false;
                })
                .catch((error) => {
                    // console.log("ERROR:", error.response.data);
                    // this.$root.mx_busy = false;
                });
            // <-- show route -->
            this.showRoute(routeId, routeName);    // FIXIT Združi v en api!!!
        },
        loadDestinationFromBase(destinationId) {
            this.$root.mx_busy = true;
            this.popupData = {};
            // <-- api -->
            const config = {
                headers: this.__getHeaders()
            };
            this.axios.get(this.uri.getDestination + `/${destinationId}`, config)
                .then((response) => {
                    this.$set(this, 'popupData', response.data.record);
                    this.$root.mx_busy = false;
                })
                .catch((error) => {
                    console.log("ERROR:", error.response.data);
                });

        },
    },
    computed: {
        getTemplateHeight() {
            let height = this.__layoutCenterHeightCalculate.value - this.$adventuresSubHeight;
            // <--  -->
            return {
                value: height,
                px: height + 'px'
            }
        },
        getBodyHeight() {
            let height = this.getTemplateHeight.value - this.__layoutHeightCalculate(this.layout);
            height -= 0;
            // <--  -->
            return {
                value: height,
                px: height + 'px'
            }
        },
        // <-- pane -->
        getPaneSize() {
            // <-- minSize -->
            let minSize = (this.paneMinWidth.value + 60) * 100 / this.templateLayout.width;
            minSize = minSize.toFixed(0);
            // <-- panelMapMinSize -> maxSize  -->
            let maxSize = (this.templateLayout.width - this.paneMapMinWidth.value) * 100 / this.templateLayout.width;
            maxSize = maxSize.toFixed(2) - 0.5;
            // <-- when monitor is smaller then minSize + maxSize -->
            if (minSize > maxSize) {
                minSize = 50;
                maxSize = 50;
            }
            // <--  -->
            return {
                min: minSize,
                max: 80, // maxSize, // TODO change pane value!!!
                size: 50,
            }
        },
        // <--  -->
        isBookingDisabled() {
            return !this.$root.mx_isLogin;
        },
        // <-- pictures -->
        getPictures() {
            let selectedRouteId = this.selectedRouteId;
            if (this.__isNull(this.adventures[selectedRouteId])) {
                return [];
            }
            // <-- destinations pictures -->
            let pictures = this.__clone(this.adventures[selectedRouteId].pictures);
            pictures.forEach(picture => {
                picture.image.src = this.__getImageSrc(picture.image.src);
                picture.image.src_p = this.__getImageSrc(picture.image.src_p);
                picture.modalSizeImageWidth = this.imageWidthMax;
                picture.modalImageSize = this.__imageSizeOptimum(this.imageWidthMax, this.imageHeightMax, picture.image.width, picture.image.height);
            });
            return pictures;
        },
        // <-- booking -->
        getRouteForBooking() {
            if (this.__isNull(this.adventures) || this.__isNull(this.adventures[this.selectedRouteId])) {
                return {
                    routeId: 0,
                    routeName: null
                };
            }
            // <--  -->
            let route = this.route; //this.adventures[this.selectedRouteId];
            // <--  -->
            return {
                routeId: route.id,
                routeName: route.name
            };
        }
    },
    filters: {},
    watch: {
        '$root.mx_isLogin': {
            handler: function (value, oldValue) {
                if (value) {
                    this.loadRoutesFromBase();
                }
            },
            deep: true
        },
        'hotlink': {
            handler: function (value, oldValue) {
                if (value.source !== this.$options.name) {
                    if (value.popupOpen && this.popupData.id !== value.destinationId) {
                        this.loadDestinationFromBase(value.destinationId);
                    }
                    //     this.setExperienceSelect();
                    //     // <-- scrollTo -->
                    //     this.scrollToExperience();
                }
            },
            deep: true
        },
    }
}
</script>

<style scoped>

</style>
