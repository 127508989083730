<template>
    <b-modal
        :id="$options.name"
        :title="getRoute.title"
        :cancel-title="$t('form.cancel')"
        :ok-title="$t('form.save')"
        header-bg-variant="primary"
        size="lg"
        @ok="formSave"
    >
        <!--            :scrollable="!getRecords > 3"-->
        <b-overlay :opacity="overlay.opacity" :show="overlay.visible" rounded="sm">

            <div class="p-2">
                <!-- departure date, time -->
                <b-row>
                    <!-- departure date -->
                    <b-col cols="12" sm="8" lg="6" md="8" class="">
                        <h5>{{$t('adventure.route.departure_date')}}*:</h5>
                        <b-form-datepicker
                            v-model="record.content.departureDate"
                            :date-format-options="{year: 'numeric', month: 'long', day: '2-digit'}"
                            :start-weekday="__localeContent.firstDayOfWeek"
                            :locale="__getVueLocale()"
                            :min="getDateRestrictions.minDate"
                            :max="getDateRestrictions.maxDate"
                            label-help=""
                            label-no-date-selected=""
                            close-button
                            :label-close-button="$t('form.datepicker.close')"
                            :label-reset-button="$t('form.datepicker.reset')"
                        ></b-form-datepicker>
                    </b-col>
                    <!-- departure time -->
                    <b-col cols="12" sm="4" lg="6" md="4" class="mt-2 mt-sm-0">
                        <h5>{{$t('adventure.route.departure_time')}}*:</h5>
                        <b-form-group>
                            <b-form-input
                                v-model="record.content.departureTime"
                                type="time"
                                style="width: 110px"
                            ></b-form-input>
                        </b-form-group>
                    </b-col>
                </b-row>
                <!-- arrival date, time -->
                <b-row>
                    <!-- arrival date -->
                    <b-col cols="12" sm="8" lg="6" md="8" class="">
                        <h5>{{$t('adventure.route.arrival_date')}}:</h5>
                        <b-form-datepicker
                            v-model="record.content.arrivalDate"
                            :date-format-options="{year: 'numeric', month: 'long', day: '2-digit'}"
                            :start-weekday="__localeContent.firstDayOfWeek"
                            :locale="__getVueLocale()"
                            :min="getArrivalLimits.minDate"
                            :max="getDateRestrictions.maxDate"
                            label-help=""
                            label-no-date-selected=""
                            close-button
                            :label-close-button="$t('form.datepicker.close')"
                            reset-button
                            reset
                            :label-reset-button="$t('form.datepicker.reset')"
                            :disabled="getArrivalLimits.isDateDisabled"
                        ></b-form-datepicker>
                    </b-col>
                    <!-- arrival time -->
                    <b-col cols="12" sm="4" lg="6" md="4" class="mt-2 mt-sm-0">
                        <h5>{{$t('adventure.route.arrival_time')}}:</h5>
                        <b-form-group>
                            <b-form-input
                                v-model="record.content.arrivalTime"
                                type="time"
                                style="width: 110px"
                                :disabled="getArrivalLimits.isTimeDisabled"
                            ></b-form-input>
                        </b-form-group>
                    </b-col>
                </b-row>
                <!-- guests -->
                <b-row class="my-4">
                    <b-col cols="12">
                        <h5>{{$t('adventure.experience.guests')}}:</h5>
                    </b-col>
                    <!-- adult -->
                    <b-col cols="12" sm="6" md="6" lg="3" xl="3">
                        <b-form-group
                            :label="$tc('adventure.experience.adult', 1) + ' (13+):'"
                        >
                            <b-form-spinbutton
                                size="sm"
                                id="guestsAdult" v-model="record.content.guests.adult" min="0"
                            >
                            </b-form-spinbutton>
                        </b-form-group>
                    </b-col>
                    <b-col cols="12" sm="6" md="6" lg="3" xl="3">
                        <!-- child -->
                        <b-form-group
                            :label="$tc('adventure.experience.child', 1) + ' (3-12):'"
                        >
                            <b-form-spinbutton
                                size="sm"
                                id="guestsChild" v-model="record.content.guests.child" min="0"
                            >
                            </b-form-spinbutton>
                        </b-form-group>
                    </b-col>
                    <!-- toddler -->
                    <b-col cols="12" sm="6" md="6" lg="3" xl="3">
                        <b-form-group
                            :label="$tc('adventure.experience.toddler', 1) + ' (0-2):'"
                        >
                            <b-form-spinbutton
                                size="sm"
                                id="guestsToddler" v-model="record.content.guests.toddler" min="0"
                            >
                            </b-form-spinbutton>
                        </b-form-group>
                    </b-col>
                    <!-- senior -->
                    <b-col cols="12" sm="6" md="6" lg="3" xl="3">
                        <b-form-group
                            :label="$tc('adventure.experience.senior', 1) + ' (65+):'"
                        >
                            <b-form-spinbutton
                                size="sm"
                                id="guestsSenior" v-model="record.content.guests.senior" min="0"
                            >
                            </b-form-spinbutton>
                        </b-form-group>
                    </b-col>
                    <!-- button ... -->
                    <b-col cols="12">
                        <b-button
                            size="sm"
                            variant="secondary"
                            v-b-toggle="'collapse-guests'"
                        >
                            {{$t('adventure.route.organised')}}
                        </b-button>
                        <b-collapse id="collapse-guests">
                            <b-row class="mt-3">
                                <!-- schoolchild -->
                                <b-col cols="12" sm="6" md="6" lg="4" xl="4">
                                    <b-form-group
                                        :label="$tc('adventure.experience.schoolchild', 1)"
                                    >
                                        <b-form-spinbutton
                                            size="sm"
                                            id="guestsSchoolchild" v-model="record.content.guests.schoolchild" min="0"
                                        >
                                        </b-form-spinbutton>
                                    </b-form-group>
                                </b-col>
                                <!-- escort -->
                                <b-col cols="12" sm="6" md="6" lg="4" xl="4">
                                    <b-form-group
                                        :label="$tc('adventure.experience.escort', 1)"
                                    >
                                        <b-form-spinbutton
                                            size="sm"
                                            id="guestsEscort" v-model="record.content.guests.escort" min="0"
                                        >
                                        </b-form-spinbutton>
                                    </b-form-group>
                                </b-col>
                                <!-- bus driver -->
                                <b-col cols="12" sm="6" md="6" lg="4" xl="4">
                                    <b-form-group
                                        :label="$tc('adventure.experience.bus_driver', 1)"
                                    >
                                        <b-form-spinbutton
                                            id="guestsBusDriver"
                                            v-model="record.content.guests.bus_driver"
                                            size="sm"
                                            min="0"
                                        >
                                        </b-form-spinbutton>
                                    </b-form-group>
                                </b-col>
                            </b-row>
                        </b-collapse>
                    </b-col>
                </b-row>
                <!-- tour languages -->
                <!--                <b-row class="">
                                    <b-col cols="12">
                                        <h5>4. {{$t('adventure.experience.language')}}:</h5>
                                    </b-col>
                                    <b-col cols="12">
                                        <b-button
                                            size="sm"
                                            pill
                                            variant="white"
                                            class="border-primary"
                                        >
                                            &lt;!&ndash;                            variant="white" &ndash;&gt;
                                            &lt;!&ndash;TODO onselect variant="secondary"&ndash;&gt;
                                            slovenščina
                                        </b-button>
                                    </b-col>
                                </b-row>-->
            </div>
        </b-overlay>
        <!-- alert error -->
        <b-alert
            v-model="errors.visible"
            class="mb-0"
            dismissible
            variant="danger"
        >
            <!--            @dismissed="resetErrors"-->
            <div v-html="errors.message"></div>
        </b-alert>
    </b-modal>
</template>

<script>

// @group ADVENTURE2
export default {
    name: "ModalRouteBooking2",
    components: {},
    props: {
        // Data for booking the route
        route: {
            type: Object,
            default() {
                return {}
            }
        },
    },
    data() {
        return {
            overlay: {
                visible: false,
                opacity: 0.7,
            },
            /**
             * @vuese
             * URLs for calling api
             */
            uri: {
                main: `api/v1/booking/bookings`,
            },
            errors: {
                visible: false,
                message: null
            },
            record: {
                id: null,
                route_id: null,
                content: {
                    bookingType: 'route',
                    departureDate: null,
                    departureTime: null,
                    arrivalDate: null,
                    arrivalTime: null,
                    guests: {
                        adult: 0,
                        child: 0,
                        toddler: 0,
                        senior: 0,
                        schoolchild: 0,
                        escort: 0,
                        bus_driver: 0,
                    }
                },
            },
        }
    },
    mounted() {},
    methods: {
        showOverlay(show) {
            this.overlay.visible = show;
        },
        // <--  -->
        // hideModal(data) {
        //     this.$emit('eventRefreshBookings', data);
        //     // <-- hide the modal manually -->
        //     this.$nextTick(() => {
        //         this.$bvModal.hide(this.$options.name);
        //     });
        // },
        // <--  -->
        checkValidity() {
            this.errors = {
                visible: false,
                message: null
            };
            // <--  -->
            let message = '';
            // <-- date -->
            let departureDate = this.record.content.departureDate;
            if (this.__isNull(departureDate)) {
                message = this.$t('adventure.route.error_messages.departure_date_missing');
            }
            // <-- time -->
            let departureTime = this.record.content.departureTime;
            if (this.__isNull(departureTime)) {
                message += this.__isNull(message) ? '' : '<br>';
                message += this.$t('adventure.route.error_messages.departure_time_missing');
            }
            // <-- arrival date -->
            let arrivalDate = this.record.content.arrivalDate;
            if (!this.__isNull(arrivalDate) && arrivalDate < departureDate) {
                message += this.__isNull(message) ? '' : '<br>';
                message += this.$t('adventure.route.error_messages.inappropriate_arrival_date');
            }
            // <-- arrival time -->
            let arrivalTime = this.record.content.arrivalTime;
            if (!this.__isNull(arrivalDate) && arrivalDate === departureDate && arrivalTime <= departureTime) {
                message += this.__isNull(message) ? '' : '<br>';
                message += this.$t('adventure.route.error_messages.inappropriate_arrival_time');
            }
            // <-- guests -->
            let guests = this.record.content.guests;
            let guestsNumber = guests.adult + guests.senior + guests.escort + guests.bus_driver;
            if (guestsNumber <= 0) {
                message += this.__isNull(message) ? '' : '<br>';
                message += this.$t('adventure.route.error_messages.inappropriate_guest_members');
            }
            // <--  -->
            this.errors.message = message;
            return message.length === 0;
        },
        // <--  -->
        formSave(bvModalEvent) {
            bvModalEvent.preventDefault();
            // <--  -->
            if (!this.checkValidity()) {
                this.errors.visible = true;
                return;
            }
            // <--  -->
            this.$set(this.record, 'route_id', this.getRoute.routeId);
            if (this.checkValidity()) {
                if (this.record.id > 0) {
                    this.baseUpdate();
                    return;
                }
                this.baseInsert();
            }
        },
        // <-- base -->
        baseInsert() {
            this.showOverlay(true);
            let data = this.record;
            // <-- api -->
            const config = {
                headers: this.__getHeaders()
            };
            // <-- axios -->
            this.axios.post(this.uri.main, data, config)
                .then((response) => {
                    this.showOverlay(false);
                    this.$root.mx_countBooked = response.data.length;
                    this.$bvModal.hide(this.$options.name);
                    // this.hideModal();
                })
                .catch((error) => {
                    // error = error.response.data;
                    // this.errors.message = error;
                    // this.showOverlay(false);
                });
        },
        baseUpdate() {
            // this.showOverlay(true);
            // // <--  -->
            // let record = this.record;
            // // <-- clear errors -->
            // this.resetErrors();
            // // <-- data -->
            // let data = {
            //     'locale': this.$i18n.locale,
            //     'record': record
            // };
            // // <-- api -->
            // const config = {
            //     headers: this.__getHeaders()
            // };
            // // <-- axios -->
            // this.axios.put(this.uri.main + `/${this.record.id}`, data, config)
            //     .then((response) => {
            //         this.hideModal(response.data);
            //         this.showOverlay(false);
            //     })
            //     .catch((error) => {
            //         error = error.response.data;
            //         this.errors.message = error;
            //         this.showOverlay(false);
            //     });
        },
    },
    computed: {
        getRoute() {
            let object = {};
            if (this.__isNull(this.route)) {
                return object;
            }
            let route = this.route;
            // <--  -->
            object.routeId = route.id;
            object.title = this.$t('adventure.route.booking_route_title', {route_name: route.name});
            // <--  -->
            return object;
        },
        getDateRestrictions() {
            const now = new Date();
            const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
            // <-- min date (now + 3 days) -->
            const minDate = new Date(today);
            minDate.setDate(minDate.getDate() + 3);
            // <-- max date (now + 1 year) -->
            const maxDate = new Date(today)
            maxDate.setYear(maxDate.getFullYear() + 1)
            // <--  -->
            return {
                minDate: minDate,
                maxDate: maxDate
            }
        },
        getArrivalLimits() {
            let departureDate = this.record.content.departureDate;
            let departureTime = this.record.content.departureTime;
            let isDateDisabled = false;
            let isTimeDisabled = false;
            let minDate = departureDate;
            let minTime = null;
            // <-- min date -->
            if (this.__isNull(departureDate)) {
                this.record.content.arrivalDate = null;
                this.record.content.arrivalTime = null;
            }
            isDateDisabled = this.__isNull(departureDate);
            isTimeDisabled = this.__isNull(departureDate);
            // <--  -->
            return {
                isDateDisabled: isDateDisabled,
                minDate: minDate,
                isTimeDisabled: isTimeDisabled,
            }
        }
    },
    filters: {},
    watch: {}
}
</script>

<style scoped>

</style>
