<template>
    <div class="main" :style="{width: width}">
        <!-- header -->
        <div class="header px-1"
             :class="`bg-${headerBgVariant}`"
             :style="{'border-radius': isInside ?  0 : null}"
        >
            <!-- title -->
            <div class="flex-grow-1 my-1 font-weight-bold" :class="`text-${headerTextVariant}`" style="font-size:10.5pt; cursor: pointer"
                 @click.stop="foldingChange()">
                {{title}}
            </div>
            <!-- buttons -->
            <div class="header-buttons d-flex">
                <!-- Additional buttons (size=sm, class="ml-1 py-0") -->
                <slot name="headerButtons"></slot>
                <!-- button folding -->
                <b-button
                    :variant="isInside ? 'white' : 'secondary'"
                    class="ml-1 py-0"
                    :class="isInside ? 'text-secondary' : ''"
                    size="sm"
                    @click.stop="foldingChange()"
                >
                    <b-icon
                        :icon="folded ? 'three-dots-vertical' : 'three-dots'"
                        scale="0.9"
                    />
                </b-button>
            </div>
        </div>
        <!-- body -->
        <div v-show="folded" class="body" :style="{background: isInside ? 'white' : 'whitesmoke'}">
            <slot name="body">
                <!-- body-->
            </slot>
        </div>
        <!-- footer -->
        <div v-show="folded" class="footer" :style="{'border-radius': isInside ?  0 : null}">
            <slot name="footer">
                <!-- footer-->
            </slot>
        </div>
    </div>
</template>

<script>
import MainBody from "@/components/general/layout/MainBody";

// @group ADVENTURE2
export default {
    name: "FoldingCard",
    components: {MainBody},
    props: {
        title: {
            type: String,
            default: 'Title'
        },
        headerBgVariant: {
            type: String,
            default: 'secondary-light'
        },
        headerTextVariant: {
            type: String,
            default: 'white'
        },
        width: {
            type: String,
            default: '100%', //'500px'
        },
        expand: {
            type: Boolean,
            default: false
        },
        isInside: {
            type: Boolean,
            default: false
        },
    },
    data() {
        return {
            folded: this.expand,
        }
    },
    mounted() {
    },
    methods: {
        foldingChange() {
            this.folded = !this.folded;
            /**
             * @vuese
             * Returning folding state
             * @return {object: boolean}
             */
            this.$emit("foldingChange", {folded: !this.folded});
        }
    },
    computed: {},
    filters: {},
    watch: {}
}
</script>

<style scoped>
.main {
    display: flex;
    flex-direction: column;
}

.header {
    min-height: 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 5px 5px 0 0;
}

.header-buttons {
    flex: 0 0 auto;
}

.body {
    margin-top: 2px;
}

.footer {
    /*min-height: 30px;*/
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 0 0 5px 5px;
}
</style>
